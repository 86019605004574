var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ts-page-title',{attrs:{"title":_vm.$t('transfer.pageTitle'),"breadcrumb":[
      { text: _vm.$t('home'), href: '/' },
      {
        text: _vm.$t('transfer.pageTitle'),
        active: true,
      } ]}}),_c('ts-panel',[_c('ts-panel-wrapper',[_c('div',{staticClass:"tw-flex tw-justify-between tw-items-center"},[_c('div',{staticClass:"tw-flex tw-space-x-2"},[_c('a-button',{attrs:{"type":"primary"},on:{"click":_vm.addNew}},[_vm._v(" "+_vm._s(_vm.$t("addNew"))+" ")]),_c('a-range-picker',{staticStyle:{"width":"200px"},attrs:{"allowClear":false,"format":"DD-MM-YYYY","valueFormat":"DD-MM-YYYY"},on:{"change":function($event){return _vm.fetchData()}},model:{value:(_vm.dateRange),callback:function ($$v) {_vm.dateRange=$$v},expression:"dateRange"}})],1),_c('div',{staticClass:"tw-space-x-3"},[_c('a-tooltip',{attrs:{"title":_vm.$t('transfer.search')}},[_c('a-input-search',{staticStyle:{"width":"200px"},attrs:{"placeholder":_vm.$t('transfer.search')},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)])]),_c('div',{staticClass:"tw-overflow-x-scroll"},[_c('ts-table',{attrs:{"columns":_vm.columns,"records":_vm.resources,"loading":_vm.loading},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var record = ref.record;
return [_c('td',{staticClass:"tw-whitespace-nowrap"},[_vm._v(_vm._s(record.transaction_date))]),_c('td',[_vm._v(_vm._s(record.delivery_start_time))]),_c('td',[_vm._v(_vm._s(record.booking_number))]),_c('td',[_vm._v(_vm._s(record.shop_name))]),_c('td',[_vm._v(_vm._s(record.phone_number))]),_c('td',[_vm._v(_vm._s(record.service_type_name_kh))]),_c('td',[_vm._v(_vm._s(record.package_type_kh))]),_c('td',[_vm._v(_vm._s(record.number_of_package))]),_c('td',[_vm._v(_vm._s(record.receiver_phone))]),_c('td',[_vm._v(_vm._s(record.receiver_location))]),_c('td',{staticClass:"tw-whitespace-nowrap"},[_c('p',{staticClass:"tw-mb-0 tw-space-x-2"},[_c('span',{staticClass:"tw-text-blue-400 tw-font-semibold"},[_vm._v(_vm._s(record.from_driver_name))]),_c('i',{staticClass:"fas fa-arrow-right tw-text-red-600"}),_c('span',{staticClass:"tw-text-yellow-600 tw-font-semibold"},[_vm._v(_vm._s(record.to_driver_name))])])])]}}])})],1),_c('div',{staticClass:"d-flex justify-content-end tw-p-4"},[_c('ts-pagination',{on:{"navigate":_vm.fetchData},model:{value:(_vm.pagination),callback:function ($$v) {_vm.pagination=$$v},expression:"pagination"}})],1),(_vm.show_form)?_c('a-modal',{attrs:{"title":_vm.$t('transfer.pageTitle'),"footer":null,"centered":true,"width":"750px","zIndex":1020},on:{"cancel":_vm.onModalClose},model:{value:(_vm.show_form),callback:function ($$v) {_vm.show_form=$$v},expression:"show_form"}},[_c('Form',{on:{"cancel":_vm.onModalClose}})],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }