<template>
  <ts-loading-banner :loading="loading">
    <div class="tw-space-y-2">
      <div class="row">
        <div class="col-md-12 tw-space-y-2">
          <label class="required">{{ $t("transfer.transferFrom") }}</label>
          <a-select
            show-search
            style="width: 100%"
            :default-active-first-option="false"
            :show-arrow="false"
            :filter-option="false"
            :not-found-content="null"
            :placeholder="$t('transfer.typeToSearch')"
            @search="handleFromDriverSearch"
            @change="handleFromDriverChange"
            :loading="fetch_driver_from"
          >
            <a-select-option v-for="c in transferFromDriver" :key="c.driver_id">
              {{ c.driver_name }}
            </a-select-option>
          </a-select>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 tw-space-y-2">
          <label class="required">{{ $t("transfer.toDriver") }}</label>
          <a-select
            show-search
            v-model="model.transfer_to_driver_id"
            style="width: 100%"
            :default-active-first-option="false"
            :show-arrow="false"
            :filter-option="false"
            :not-found-content="null"
            :placeholder="$t('transfer.typeToSearch')"
            @search="handleDriverSearch"
            :loading="fetch_driver"
          >
            <a-select-option v-for="c in driverList" :key="c.driver_id">
              {{ c.driver_name }}
            </a-select-option>
          </a-select>
          <div
            class="tw-text-red-500"
            v-if="errors.has('transfer_to_driver_id')"
          >
            {{ errors.first("transfer_to_driver_id") }}
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 tw-space-y-2">
          <label>{{ $t("transfer.bookingList") }}</label>
          <div class="tw-border tw-rounded tw-p-2 tw-overflow-y-scroll">
            <table class="tw-w-full">
              <thead>
                <tr class="tw-bg-blue-400 tw-text-white">
                  <th
                    class="
                      tw-uppercase
                      tw-whitespace-nowrap
                      tw-py-1
                      tw-px-2
                      tw-bg-blue-500
                      tw-text-white
                      tw-border
                      tw-w-5
                    "
                  >
                    <ts-checkbox
                      :checkedValue="checkAll"
                      @change="onCheckAll"
                    ></ts-checkbox>
                  </th>
                  <th class="tw-px-2 tw-py-1 tw-border">
                    {{ $t("transfer.bookingNumber") }}
                  </th>
                  <th class="tw-px-2 tw-py-1 tw-border">
                    {{ $t("transfer.receiverPhone") }}
                  </th>
                  <th class="tw-px-2 tw-py-1 tw-border">
                    {{ $t("transfer.receiverLocation") }}
                  </th>
                  <th class="tw-px-2 tw-py-1 tw-border">
                    {{ $t("transfer.packageType") }}
                  </th>
                  <th class="tw-px-2 tw-py-1 tw-border">
                    {{ $t("transfer.numberOfPackage") }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(b, index) in bookingList" :key="index">
                  <td class="tw-px-2 tw-py-1 tw-border">
                    <ts-checkbox
                      v-model="model.delivery_id"
                      :value="b.delivery_id"
                    ></ts-checkbox>
                  </td>
                  <td class="tw-px-2 tw-py-1 tw-border">
                    {{ b.booking_number }}
                  </td>
                  <td class="tw-px-2 tw-py-1 tw-border">
                    {{ b.receiver_phone }}
                  </td>
                  <td class="tw-px-2 tw-py-1 tw-border">
                    {{ b.receiver_location }}
                  </td>
                  <td class="tw-px-2 tw-py-1 tw-border">
                    {{ b.package_type_kh }}
                  </td>
                  <td class="tw-px-2 tw-py-1 tw-border">
                    {{ b.number_of_package }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="tw-text-red-500" v-if="errors.has('delivery_id')">
            {{ errors.first("delivery_id") }}
          </div>
        </div>
      </div>
      <div class="row tw-mt-4">
        <div class="col-md-12 tw-space-x-4 tw-flex tw-justify-end">
          <ts-button @click.prevent="$emit('cancel')">{{
            $t("cancel")
          }}</ts-button>
          <ts-button color="primary" @click.prevent="onSave">{{
            $t("transfer.transfer")
          }}</ts-button>
        </div>
      </div>
    </div>
  </ts-loading-banner>
</template>

<script>
import { debounce } from "lodash";
import { mapActions } from "vuex";
import { Errors } from "form-backend-validation";
export default {
  name: "transferForm",
  data() {
    return {
      loading: false,
      transferFromDriver: [],
      driverList: [],
      fetch_driver: false,
      fetch_driver_from: false,
      errors: new Errors(),
      search_booking: "",
      bookingList: [],
      model: {
        delivery_id: [],
        transfer_to_driver_id: undefined,
      },
    };
  },
  computed: {
    checkAll() {
      if (this.model.delivery_id.length == 0) return false;
      return this.bookingList.length == this.model.delivery_id.length;
    },
  },
  methods: {
    ...mapActions("driver/transfer", ["getFormViewData", "booking", "store"]),
    onCheckAll(e) {
      if (e) {
        this.model.delivery_id = [];
        this.bookingList.forEach((book) => {
          this.model.delivery_id.push(book.delivery_id);
        });
      } else {
        this.model.delivery_id = [];
      }
    },
    handleDriverSearch: debounce(function (value) {
      this.fetch_driver = true;
      this.getFormViewData({
        params: {
          fnName: "driver",
          search_driver: value,
        },
      })
        .then((response) => {
          this.driverList = response.data.driver;
        })
        .finally(() => (this.fetch_driver = false));
    }, 500),
    handleFromDriverSearch: debounce(function (value) {
      this.fetch_driver_from = true;
      this.getFormViewData({
        params: {
          fnName: "driver",
          search_driver: value,
        },
      })
        .then((response) => {
          this.transferFromDriver = response.data.driver;
        })
        .finally(() => (this.fetch_driver_from = false));
    }, 500),
    handleFromDriverChange(value) {
      this.bookingList = [];
      this.loading = true;
      this.booking(value)
        .then((response) => {
          this.bookingList = response.data;
        })
        .catch((error) => {
          this.$notify({ type: "error", text: error.message });
        })
        .finally(() => (this.loading = false));
    },
    onSave() {
      this.loading = true;
      this.store(this.model)
        .then((response) => {
          this.$notify({ type: "success", text: response.message });
          this.clearInput();
          this.$emit("cancel");
        })
        .catch((error) => {
          this.$notify({ type: "error", text: error.message });
          this.errors = new Errors(error.errors);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    clearInput() {
      this.model.delivery_id = "";
      this.model.transfer_to_driver_id = undefined;
    },
  },
};
</script>
