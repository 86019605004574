<template>
  <div>
    <ts-page-title
      :title="$t('transfer.pageTitle')"
      :breadcrumb="[
        { text: $t('home'), href: '/' },
        {
          text: $t('transfer.pageTitle'),
          active: true,
        },
      ]"
    />
    <ts-panel>
      <ts-panel-wrapper>
        <div class="tw-flex tw-justify-between tw-items-center">
          <div class="tw-flex tw-space-x-2">
            <a-button type="primary" @click="addNew">
              {{ $t("addNew") }}
            </a-button>
            <a-range-picker
              v-model="dateRange"
              :allowClear="false"
              format="DD-MM-YYYY"
              valueFormat="DD-MM-YYYY"
              style="width: 200px"
              @change="fetchData()"
            />
          </div>
          <div class="tw-space-x-3">
            <a-tooltip :title="$t('transfer.search')">
              <a-input-search
                v-model="search"
                :placeholder="$t('transfer.search')"
                style="width: 200px"
              />
            </a-tooltip>
          </div>
        </div>
      </ts-panel-wrapper>
      <div class="tw-overflow-x-scroll">
        <ts-table :columns="columns" :records="resources" :loading="loading">
          <template v-slot="{ record }">
            <td class="tw-whitespace-nowrap">{{ record.transaction_date }}</td>
            <td>{{ record.delivery_start_time }}</td>
            <td>{{ record.booking_number }}</td>
            <td>{{ record.shop_name }}</td>
            <td>{{ record.phone_number }}</td>
            <td>{{ record.service_type_name_kh }}</td>
            <td>{{ record.package_type_kh }}</td>
            <td>{{ record.number_of_package }}</td>
            <td>{{ record.receiver_phone }}</td>
            <td>{{ record.receiver_location }}</td>
            <td class="tw-whitespace-nowrap">
              <p class="tw-mb-0 tw-space-x-2">
                <span class="tw-text-blue-400 tw-font-semibold">{{
                  record.from_driver_name
                }}</span>
                <i class="fas fa-arrow-right tw-text-red-600"></i>
                <span class="tw-text-yellow-600 tw-font-semibold">{{
                  record.to_driver_name
                }}</span>
              </p>
            </td>
          </template>
        </ts-table>
      </div>
      <div class="d-flex justify-content-end tw-p-4">
        <ts-pagination
          v-model="pagination"
          @navigate="fetchData"
        ></ts-pagination>
      </div>
      <a-modal
        v-if="show_form"
        v-model="show_form"
        :title="$t('transfer.pageTitle')"
        :footer="null"
        :centered="true"
        width="750px"
        :zIndex="1020"
        @cancel="onModalClose"
      >
        <Form @cancel="onModalClose" />
      </a-modal>
    </ts-panel>
  </div>
</template>

<script>
import { debounce } from "lodash";
import { mapState } from "vuex";
import Form from "./form.vue";

export default {
  name: "bookingIndex",
  components: {
    Form,
  },
  data() {
    return {
      show_form: false,
      loading: false,
    };
  },
  computed: {
    ...mapState("driver/transfer", ["resources", "pagination"]),
    search: {
      get() {
        return this.$store.state.driver.transfer.search;
      },
      set(newValue) {
        this.$store.commit("driver/transfer/SET_SEARCH", newValue);
        this.$store.commit("driver/transfer/RESET_CURRENT_PAGE");
      },
    },
    columns() {
      return [
        {
          name: this.$t("transfer.transactionDate"),
          style: "width:30px", sortKey: 'transaction_date'
        },
        { name: this.$t("transfer.startDelivery") , sortKey: 'delivery_start_time'},
        { name: this.$t("transfer.bookingNumber"), sortKey: 'booking_number' },
        { name: this.$t("transfer.shopName"), sortKey: 'shop_name' },
        { name: this.$t("transfer.shopPhone"), sortKey: 'phone_number' },
        { name: this.$t("transfer.serviceType"), sortKey: 'service_type_name_kh' },
        { name: this.$t("transfer.packageType"), sortKey: 'package_type_kh' },
        { name: this.$t("transfer.numberOfPackage"), sortKey: 'number_of_package' },
        { name: this.$t("transfer.receiverPhone"), sortKey: 'receiver_phone' },
        { name: this.$t("transfer.receiverLocation"), sortKey: 'receiver_location' },
        { name: this.$t("transfer.fromToDriver") },
      ];
    },
    dateRange: {
      get() {
        return this.$store.state.driver.transfer.dateRange;
      },
      set(newValue) {
        this.$store.commit("driver/transfer/SET_DATE_RANGE", newValue);
      },
    },
  },
  methods: {
    fetchData(attributes) {
      this.loading = true;
      this.$store
        .dispatch("driver/transfer/fetch", { ...attributes })
        .then(() => {
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          this.$notify({ type: "error", text: error.message });
        });
    },
    addNew() {
      this.show_form = true;
    },
    onModalClose() {
      this.show_form = false;
      this.fetchData();
    },
  },
  watch: {
    search: debounce(function () {
      this.fetchData();
    }, 500),
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.fetchData();
    });
  },
  beforeRouteLeave(to, from, next) {
    this.$store.commit("driver/transfer/RESET_STATE");
    next();
  },
};
</script>
